import React from "react";
import { graphql } from "gatsby";
import { markdownCleaner } from "../utils/markdownCleaner";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/Seo/seo";
import SectionSeparator from "../components/layout/section-separator";
import CTASection from "../components/misc/cta-section";
import GlossaryList from "../components/resources/glossary-list";

const GlossaryEntry = ({ data, pageContext }) => {
    const page = data.contentfulGlossaryEntry;
    const menus = pageContext.menus?.items;
    const title = page.glossary_term_name ? page.glossary_term_name : page.page_title;

    const SEOTitle = page.page_title || title + " | Stitch Data Glossary";
    const bodyClasses = `resources resources_glossary resources_glossary_${pageContext.slug}`;

    let content = "";

    if (page.description) {
        content = markdownCleaner(page.description.description);
    } else {
        content = markdownCleaner("Use this definitive guide to data definitions and trends, from the team at Stitch.");
    }

    const description =
        {
            "machine-learning":
                "Machine learning is the science of getting computers to learn and act like humans do, and improve their learning over time in autonomous fashion, by feeding them data and information in the form of observations and real-world interactions.",
            "etl-pipeline":
                "A data pipeline is a general term for a process that moves data from a source to a destination. But what about ETL pipeline? What's the difference? Click to learn more.",
            "information-lifecycle-management":
                "ILM is comprised of the policies, processes, practices, and tools used to align the business value of information with the most appropriate and cost-effective IT infrastructure.",
            "business-intelligence-bi":
                "Business intelligence (BI) leverages software and services to transform data into actionable intelligence that informs an organization's strategic and tactical business decisions.",
            "data-science": "Stitch is a simple, powerful ETL tool built for developers, by developers.",
            cte:
                "Common table expressions (CTE) are used to simplify queries 'by deconstructing ordinarily complex queries into simple blocks to be used, and reused if necessary, in rewriting the query' (Chartio).",
            "data-engineering":
                "Data engineering is a new enough role that each organization defines it a little differently. Click to learn more about data engineering and what it means.",
            "data-analytics":
                "Data analysis is a process of inspecting, cleansing, transforming, and modeling data with the goal of discovering useful information, informing conclusions, and supporting decision-making",
            "big-data":
                "Big data is a term used to refer to the study and applications of data sets that are too complex for traditional ... software to adequately deal with",
            analytics:
                "Analytics has emerged as a catch-all term for a variety of different business intelligence (BI)- and application-related initiatives. For some, it is the process of analyzing information from a particular domain, such as website analytics.",
            "data-exploration":
                "Data exploration is the initial step in data analysis, where users explore a large data set in an unstructured way to uncover initial patterns, characteristics, and points of interest.",
            "data-visualization":
                "Data visualization is 'the graphical presentation of information, with the goal of providing the viewer with a qualitative understanding of the information contents'",
            "data-modeling":
                "Data modeling techniques and methodologies are used to model data in a standard, consistent, predictable manner in order to manage it as a resource.",
            "enterprise-service-bus":
                "An enterprise service bus (ESB) is an integrated platform that provides fundamental interaction and communication services for complex software applications via an event-driven and standards-based messaging engine.",
            elt:
                "ELT (extract, load, transform) 'provides a modern alternative to ETL' (Panoply). 'Instead of transforming the data before it's written, ELT leverages the target system to do the transformation.'",
            "data-enrichment":
                "Data enrichment is a general term that refers to processes used to enhance, refine, or otherwise improve raw data. For example, correct likely misspellings or typographical errors in a database through the use of precision algorithms",
            "data-migration":
                "Data migration is the process of selecting, preparing, extracting, and transforming data and permanently transferring it from one computer storage system to another.",
            saas:
                "Software-as-a-Service (SaaS) is a software licensing model in which access to the software is provided on a subscription basis, with the software being located on external servers rather than on servers located in-house.",
            "service-oriented-architecture":
                "A service-oriented architecture (SOA) is a style of software design where services are provided to the other components by application components, through a communication protocol over a network.",
            deduplication:
                "Data deduplication refers to the elimination of redundant data. In the deduplication process, duplicate data is deleted or linked together, leaving only one copy of the data to be stored.",
            "master-data-management":
                "Master data management (MDM) is a way of 'enabling an enterprise to link all of its critical data to a common point of reference.'",
            "data-preparation":
                "Data preparation is the process of gathering, combining, structuring, and organizing data so it can be analyzed as part of data visualization, analytics, and machine learning applications.",
            "data-lake":
                "A data lake is a storage repository that holds a vast amount of raw data in its native format, including structured, semistructured, and unstructured data.",
            "data-mining":
                "Data mining is the process of analyzing hidden patterns of data according to different perspectives for categorization into useful information, which is collected and assembled in common areas, such as data warehouses, for efficient analysis.",
            "data-architecture":
                "Data architecture [describes] 'the way data will be processed and stored; how the data flows and is used by the project teams, including the data models (conceptual, logical, physical, and dimensional).'",
            "data-wrangling":
                "Data wrangling is the process of gathering, selecting, and transforming data to answer an analytical question. Also known as data cleaning or 'munging'.",
            etl:
                "ETL is short for extract, transform, load — three database functions that are combined into one tool to pull data out of one database and place it into another database.",
            "data-warehouse":
                "A 'data warehouse' is a system used for reporting and data analysis, and is considered a core component of business intelligence. ",
            "data-ingestion":
                "Data ingestion is 'the overall process of collecting, transferring, and loading data from one or multiple sources so that it may be analyzed immediately or stored in a database for later use'",
            database:
                "A database is a set of tables composed of records and fields that hold data. 'Different tables contain information about different types of things.'",
            "data-pipeline":
                "A data pipeline is a set of actions that extract data (or directly analytics and visualization) from various sources.",
            "data-integration":
                "Data integration is the combination of technical and business processes used to combine data from disparate sources into meaningful and valuable information.",
        }[page.slug] || null;

    return (
        <Layout menus={menus} headerType={pageContext.type} pageHeading={title}>
            <Seo
                type={"Page"}
                title={SEOTitle}
                description={description}
                slug={`/resources/glossary/${page.slug}`}
                bodyClass={bodyClasses}
            />
            <section className="st-section st-section--narrow" dangerouslySetInnerHTML={{ __html: content }} />
            <SectionSeparator />
            <GlossaryList
                heading={`More from the data glossary`}
                subHeading={`A definitive guide to data definitions and trends, from the team at Stitch.`}
            />
            <CTASection
                heading={`Give Stitch a try, on us`}
                subheading={`Stitch streams all of your data directly to your analytics warehouse.`}
                dualCTA={true}
                containerClasses={`st-section--lowlight`}
            />
        </Layout>
    );
};

export default GlossaryEntry;

export const query = graphql`
    query($slug: String!) {
        contentfulGlossaryEntry(slug: { eq: $slug }) {
            slug
            page_title
            glossary_term_name
            description {
                description
            }
        }
    }
`;
